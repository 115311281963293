import { VerificationRequest } from "../types/verification";

const API = `${process.env.VERIFICATOR_PATH}`;

export function sendVerificationCode(data: VerificationRequest) {
  return fetch(API, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.text());
}

export function checkVerificationCode(verificationId: string, code: number | string) {
  return fetch(`${API}/${verificationId}/check?code=${code}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.text());
}
