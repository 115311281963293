import { getRequestString } from "./base";

export async function getCreditPaymentPerMonthWithoutTax(
  price: number,
  taxbableFees: number,
  termsInMoths: number,
  apr: number,
  tradeIn: number,
  tradeInOwed: number,
  downPayment: number,
): Promise<number> {
  const API = `${process.env.FINANCE_PATH}/finance/rawMonthPaymentWithoutTax`;
  const strValue = await getRequestString(API, {
    price,
    apr,
    terms: termsInMoths,
    tradeIn,
    tradeInOwed,
    downPayment,
    dealerFees: taxbableFees,
  });
  return strValue ? parseFloat(strValue) : 0;
}
