"use client";

import { useEffect, useMemo, useState, FC, useCallback } from "react";
import cx from "classnames";
import FinanceInfoBlock from "./finance-info-block";
import { ContactType, VehicleInterestType, VehicleLeadData } from "../../../types/lead";
import { Vehicle } from "../../../types/vehicle";
import ContactForm from "../contact-form";
import PersonalCode from "../../page-components/popup/lead/vdp-popup/personal-code";
import CustomizeFinance from "./customize-finance";
import Button from "../button";
import styles from "./finance-calculator.module.scss";
import ThankYou from "../../page-components/popup/lead/vdp-popup/thank-you";
import { useIsMobile } from "../../../utils/useIsMobile";
import { formatNumber } from "../../../utils/stringFormatter";
import GoBackIcon from "../../icons/go-back-icon";
import { FinanceCalculatorConfig, PageContext } from "../../../types/ui";
import { ClientSiteData } from "../../../types/site";
import { addContactLead, addVehicleLead } from "../../../api/lead";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import { sendAnalyticsEvent, createAnalyticsDataFromVehicle } from "../../../utils/analitics";
import { getTranslationModule, Translations } from "../../../utils/translations";

interface IFinanceCalculator {
  siteData: ClientSiteData;
  currentVehicle?: Vehicle;
  config: FinanceCalculatorConfig;
  pageContext: PageContext;
  onClose?: () => void;
  popup?: boolean;
  translations: Translations;
}

const FINANCE_STORAGE_KEY = "finance";
interface FinanceStorageData {
  time: string;
}

const FinanceCalculator: FC<IFinanceCalculator> = ({
  siteData,
  currentVehicle,
  config,
  onClose,
  pageContext,
  popup,
  translations,
}) => {
  const leadSource = "finance-calculator";
  const [formEngagementSent, setFormEngagementSent] = useState(false);
  const vehicleEventData = createAnalyticsDataFromVehicle();
  const formEventData = useMemo(
    () => ({
      comm_type: "form",
      form_name: leadSource,
      form_type: "finance_credit",
      ...vehicleEventData,
    }),
    [vehicleEventData],
  );

  const isMobile = useIsMobile(768, pageContext.isSmartphone);
  const t = getTranslationModule(translations, "common");
  let initialStep = config.skipRegistration ? 2 : 0;
  const [currentStep, setCurrentStep] = useState<number>(initialStep);
  const defaultDealer = siteData.dealerInfos[0];
  const dealerId = currentVehicle ? currentVehicle.dealer : defaultDealer?.id;
  const contactOptions = siteData.popupConfig.contactOptions;
  const currentContactOption = contactOptions[0].value;
  const dealerPhoto =
    siteData?.noImagesPhoto === "" || siteData?.noImagesPhoto === undefined
      ? ""
      : siteData?.baseUrl + siteData?.noImagesPhoto;

  const [leadData] = useState<VehicleLeadData>({
    firstName: "",
    lastName: "",
    contactType: currentContactOption,
    phone: "",
    email: "",
    comments: "",
    source: "",
    zipCode: "",
  });

  const leadDataFormValidator = useMemo(() => {
    return new FormFieldValidator(leadData);
  }, [leadData]);

  const onSubmit = useCallback(() => {
    sendAnalyticsEvent("asc_form_engagement", { comm_status: "crm_update", ...formEventData });

    leadData.vin = currentVehicle?.vin;
    leadData.stock = currentVehicle?.stock;
    leadData.year = currentVehicle?.year;
    leadData.make = currentVehicle?.make;
    leadData.model = currentVehicle?.model;
    leadData.trim = currentVehicle?.trim;
    leadData.type = currentVehicle?.type;
    leadData.source = leadSource;
    leadData.interest = VehicleInterestType.BUY;
    leadData.siteInstanceId = siteData.id;
    leadData.siteConfigId = siteData.siteConfigId;

    if (currentVehicle) {
      leadData.price = currentVehicle?.price;
      leadData.msrp = currentVehicle?.msrp;

      addVehicleLead(dealerId, leadData).then(() => {
        sendAnalyticsEvent("asc_form_submission", {
          ...formEventData,
          department: "finance",
        });
      });
    } else {
      addContactLead(dealerId, leadData).then(() => {
        sendAnalyticsEvent("asc_form_submission", {
          ...formEventData,
          department: "finance",
        });
      });
    }
  }, [currentVehicle, dealerId, formEventData, leadData, siteData.id, siteData.siteConfigId]);

  if (typeof window !== "undefined") {
    const storageData = window.localStorage.getItem(FINANCE_STORAGE_KEY);
    if (storageData) {
      const financeData: FinanceStorageData = JSON.parse(storageData);
      if (financeData.time) {
        const date = new Date(financeData.time);
        if (new Date().getTime() < date.getTime() + config.skipInputLeadMinutes * 60 * 1000) {
          initialStep = 2;
        }
      }
    }
  }

  const onLeadDataVerified = useCallback(() => {
    window.localStorage.setItem(FINANCE_STORAGE_KEY, JSON.stringify({ time: new Date().toISOString() }));
  }, []);

  const personalCodeData = {
    reason: config.title,
    language: pageContext.locale || "",
    phoneNumber:
      leadData.contactType === ContactType.MESSAGE || leadData.contactType === ContactType.PHONE
        ? leadData.phone
        : undefined,
    email: leadData.contactType === ContactType.EMAIL ? leadData.email : undefined,
  };

  useEffect(() => {
    if (!formEngagementSent) {
      sendAnalyticsEvent("asc_form_engagement", { comm_status: "start", ...formEventData });
      setFormEngagementSent(true);
    }
  }, [formEngagementSent, formEventData]);

  return (
    <section className={styles.content}>
      {currentStep === 0 && (
        <div className={styles.screen} style={{ height: "100%" }}>
          {!isMobile && currentVehicle && (
            <FinanceInfoBlock currentVehicle={currentVehicle} dealerPhoto={dealerPhoto} translations={translations} />
          )}
          <div
            className={cx(styles.contactFormContainer, {
              [styles.contactFormContainerPage]: !popup,
            })}
          >
            <h4 className={styles.title}>{config.title}</h4>
            {isMobile && (
              <div className={styles.subtitleContainer}>
                {currentVehicle && (
                  <h5 className={styles.subtitle}>
                    {currentVehicle?.year +
                      " " +
                      currentVehicle?.make +
                      " " +
                      currentVehicle?.model +
                      " " +
                      currentVehicle?.drivetrain}
                  </h5>
                )}
                {currentVehicle && (
                  <span className={styles.subtitlePrice}>{`$${formatNumber(currentVehicle?.price || 0)}`}</span>
                )}
              </div>
            )}
            <p className={styles.description}>{config.description}</p>
            <ContactForm
              formValidator={leadDataFormValidator}
              contactOptions={contactOptions}
              zipCode={true}
              formEventData={formEventData}
              translations={translations}
            />
            <Button
              name={"next"}
              type={"primary"}
              text={t("next")}
              onClick={() => {
                if (leadDataFormValidator.validate()) {
                  sendAnalyticsEvent("asc_cta_interaction", {
                    element_text: t("next"),
                    element_type: "form",
                    event_action_result: "next_step",
                    element_title: "next",
                    link_url: "",
                  });
                  setCurrentStep(1);
                } else {
                  sendAnalyticsEvent("asc_cta_interaction", {
                    element_text: t("next"),
                    element_type: "form",
                    event_action_result: "error",
                    element_title: "next",
                    link_url: "",
                  });
                }
              }}
              className={styles.submitButton}
              ariaLabel={"go to next step"}
            />
            <p className={styles.disclaimer}>{config.disclaimer}</p>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <div
          className={cx(styles.screen, {
            [styles.screenMobile]: isMobile,
          })}
          style={{ height: isMobile ? "400px" : "350px" }}
        >
          {isMobile && (
            <button
              aria-label={"go to previous step"}
              name={"financeCalculatorGoToPreviousStep"}
              className={styles.goBack}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              <GoBackIcon />
              {t("back")}
            </button>
          )}
          {!isMobile && currentVehicle && (
            <FinanceInfoBlock
              currentVehicle={currentVehicle}
              backButtonHandler={() => setCurrentStep(currentStep - 1)}
              translations={translations}
            />
          )}
          <PersonalCode
            data={personalCodeData}
            title={config.confirmationTitle}
            buttonText={config.confirmationButtonTitle}
            description={config.confirmCodeText}
            onVerificationSuccess={() => {
              setCurrentStep(currentStep + 1);
              onLeadDataVerified();
              if (onSubmit) {
                onSubmit();
              }
            }}
            onBackHandler={() => setCurrentStep(currentStep - 1)}
            formEventData={formEventData}
            translations={translations}
          />
        </div>
      )}
      {currentStep === 2 && (
        <div className={styles.screen} style={{ height: "100%" }}>
          {isMobile && (
            <button
              aria-label={"go to previous step"}
              name={"financeCalculatorGoToPreviousStep"}
              className={styles.goBack}
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              <GoBackIcon />
              {t("back")}
            </button>
          )}
          {!isMobile && currentVehicle && (
            <FinanceInfoBlock
              currentVehicle={currentVehicle}
              backButtonHandler={() => setCurrentStep(currentStep - 1)}
              translations={translations}
            />
          )}
          <CustomizeFinance
            onSubmit={onClose ? () => setCurrentStep(currentStep + 1) : undefined}
            financeConditions={config.financeCondition}
            price={currentVehicle?.price!}
            disclaimer={config.disclaimer}
            terms={config.terms}
            popup={popup}
            showDefaultApr={config.showDeaultApr}
            formEventData={formEventData}
            translations={translations}
          />
        </div>
      )}
      {currentStep === 3 && onClose && (
        <div className={styles.screen} style={{ height: "100%" }}>
          {!isMobile && <FinanceInfoBlock currentVehicle={currentVehicle} translations={translations} />}
          <ThankYou
            handleClose={onClose}
            title={t("thank_you")}
            subtitle={t("we_appreciate")}
            finance={true}
            translations={translations}
          />
        </div>
      )}
    </section>
  );
};

export default FinanceCalculator;
