import { useCallback, useEffect, useState, FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import CheckedIcon from "../../icons/checked-icon";

interface ICheckbox {
  option: string;
  count?: number;
  className?: string;
  checked?: boolean;
  tag?: any;
  onChanged?: (value: boolean, tag?: any) => void;
  disabled?: boolean;
  children?: any;
  onBlur?: () => void;
}

const Checkbox: FC<ICheckbox> = ({ option, count, className, checked, tag, onChanged, disabled, children, onBlur }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onChangeCallback = useCallback(() => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onChanged) {
      onChanged(newValue, tag);
    }
  }, [onChanged, isChecked, tag]);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      className={cx(styles.label, className, {
        [styles.checked]: isChecked,
        [styles.disabled]: disabled,
      })}
      onClick={onChangeCallback}
      onBlur={onBlur}
    >
      <div className={styles.checkboxIcon}>
        <CheckedIcon />
      </div>
      <div className={styles.optionContainer}>
        {children}
        <span className={styles.option}>{option}</span>
        {count && <span>{`(${count})`}</span>}
      </div>
    </label>
  );
};

export default Checkbox;
